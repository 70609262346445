<template>
  <div>
    <b-row>
      <b-col cols="12" md="6">
        <!-- NEE -->
        <div>
          <p class="mb-1">
            <b-form-checkbox checked="false" class="custom-control-info" name="check-button" switch v-model="has_nee"
              style="display:inline;" />
            {{ $t('forms.dreamer_has_nee') }}
          </p>
        </div>
        <div v-if="has_nee">
          <b-form-group>
            <b-form-checkbox style="margin-bottom:5px;" v-for="option in nee" :value="option" v-model="nee_selected"
              name="nee" :key="option.id" @change="handleSelectionChange">
              {{ $t('special_needs.' + option.name) }}
            </b-form-checkbox>

          </b-form-group>

          <b-alert v-if="nee_selected.length > 0" variant="primary" show>
            <h4 class="alert-heading">
              <template v-if="nee_selected.length == 1">
                <span v-html="$t('dreamer.has_one_nee_settings')"></span>
              </template>
            </h4>
            <div class="alert-body" v-if="nee_selected.length == 1">
              <b-row>
                <b-col cols="12">
                  <b-card>
                    <p v-for="v, k in showSettings(nee_selected[0].name)" :key="k" style="margin-bottom:0;">
                      <i class="fas fa-angle-right"></i>
                      <span style="font-size:14px; font-weight:bolder;">{{ $t('customize.' + k) }}: </span>
                      <span v-for="item, idx in v" :key="item">{{ $t(settings_info[k][item]) }}{{ v.length > idx + 1 ? ',':'.'}}</span>
                    </p>
                  </b-card>
                  <b-form-checkbox style="margin-bottom:2rem;" v-model="apply_preset_customization"
                    name="apply_preset_customization">
                    {{ $t('dreamer.apply_settings') }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </div>


        <!-- TOPICS -->
        <!-- 
            @REVISAR 
            Dejamos oculta la sección de Topics ya que actualmente lo están implementados 
            -> Consultar con Lupe a futuro
        -->
        <!--   <div>
            <p class="mb-1">
              <b-form-checkbox
                checked="false"
                class="custom-control-info"
                name="check-button"
                switch
                v-model="has_topics"
                style="display:inline;"
              />
              {{ $t('forms.dreamer_has_topics')}}
            </p>
          </div>
          <div v-if="has_topics">
            <b-form-group>
              
              <b-form-checkbox 
                v-for="option in topics"
                :value="option"
                v-model="topics_selected"
                name="topic"
                :key="option.id">
                  {{ $t('topic.'+option.name) }}
              </b-form-checkbox>
                
            </b-form-group>
          </div> -->

      </b-col>
      <b-col cols="12" md="6">
        <!-- PASSWORD -->
        <div>
          <p class="mb-1">
            <b-form-checkbox checked="false" class="custom-control-info" name="check-button" switch v-model="acc_pass"
              style="display:inline;" />
            {{ $t('forms.dreamer_has_pass') }}
          </p>
        </div>
        <div v-if="acc_pass">
          <div class="row childpassword">
            <div class="col-md-12">
              <div class="childpassword__container childpassword__container--selector">
                <template v-for="index in 9">
                  <div :key="index" @click="addPassword(index)" class="childpassword__item childpassword__item--button">
                    <b-img :src="require('@/assets/images/passwords/' + index + '.png')" />
                  </div>
                </template>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="childpassword__container childpassword__container--result">
                  <template v-for="(p, index) in child_password">
                    <div :key="index" class="childpassword__item">
                      <b-img :src="require('@/assets/images/passwords/' + p + '.png')" />
                      <span class="order">{{ index + 1 }}</span>
                    </div>
                  </template>
                </div>
              </div>
              <div class="row">
                <div class=" childpassword__delete">
                  <button type="button" @click="emptyPassword" class="btn btn-danger btn-sm">
                    {{ $t('forms.delete') }}
                  </button>
                </div>
              </div>
              <b-row>
                <b-col>
                  <span style="color:red">{{ error_pass ? this.dreamer.age > 5 ? $t('children_passwords.error_pass_3') :
                    $t('children_passwords.error_pass_2') : '' }}</span>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="button" @click="submitChanges"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'">
          {{ $t('forms.save_changes') }}
        </b-button>
        <!-- <b-button variant="outline-secondary" type="button" @click="hasHistory()
          ? $router.go(-1)
          : $router.push({
            name: 'group-view',
            params: { id: group.id, }
          })" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
          {{ $t('forms.go_back') }}
        </b-button> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { editSchoolDreamer } from '@/api/routes'
import {
  BRow, BCol, BCarousel, BCarouselSlide,
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BFormDatepicker, BFormCheckbox, BFormRadio, BFormRadioGroup,
  BFormCheckboxGroup, BCardText, BCard, BImg,
  BFormSelect, BAlert
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow, BCol, BCarousel, BCarouselSlide, BAlert,
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
    BFormDatepicker, BFormCheckbox, BFormRadio, BFormRadioGroup,
    BFormCheckboxGroup, BCardText, BCard, BImg,
    BFormSelect,
    vSelect,

  },
  props: {
    dreamer: {
      type: Object,
      required: true,
    },
    nee: {
      type: Array,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      has_nee: false,
      has_topics: false,
      acc_pass: false,

      nee_selected: [],
      topics_selected: [],
      child_password: [],

      apply_preset_customization: false,

      settings_info: {
        'difficulty_level': {
          0: 'apps_levels.apprentice',
          1: 'apps_levels.intermediate',
          2: 'apps_levels.advanced',
          3: 'apps_levels.expert',
        },
        'no_rush': {
          0: 'customize.deactive',
          1: 'customize.active',
        },
        'reading_mode': {
          0: 'customize.read',
          1: 'customize.listen',
          2: 'customize.read_listen',
          3: 'customize.pictograms',
        },
        'font_type': {
          0: 'customize.standard',
          1: 'customize.linked',
          2: 'customize.capital',
          4: 'customize.dyslexic'
        },
        'accessibility_settings': {
          1: 'customize.pagination',
          2: 'customize.highlighting',
          3: 'customize.speech_synthesis',
          4: 'customize.speech_recognition',
          5: 'customize.subtitles',
        },
        'world_visualization': {
          1: 'world_visualization.space',
          2: 'world_visualization.islands',
          3: 'world_visualization.bigSpecial',
          4: 'world_visualization.smallSpecial',
          5: 'world_visualization.pixel3D',
          6: 'world_visualization.small',
          7: 'world_visualization.youth',
        }
      },

      error_pass: false
    }
  },
  mounted() {
    if (this.dreamer.special_needs.length > 0) {
      this.has_nee = true
      let nee_ids = this.dreamer.special_needs.map(n => n.id)
      let s_nee = []
      nee_ids.forEach(id => {
        s_nee.push(this.nee.find(element => element.id == id))
      });
      this.nee_selected = s_nee
    }
    if (this.dreamer.topics.length > 0) {
      this.has_topics = true

      let t_ids = this.dreamer.topics.map(n => n.id)
      let s_topics = []
      t_ids.forEach(id => {
        s_topics.push(this.topics.find(element => element.id == id))
      });
      this.topics_selected = s_topics

    }
    if (this.dreamer.password && this.dreamer.password.length > 0) {
      this.acc_pass = true
      this.child_password = JSON.parse(this.dreamer.password)
    }
  },
  watch: {
    has_nee() {
      this.apply_preset_customization = false
      if (!this.has_nee) {
        this.nee_selected = []
      }
    },
    nee_selected: {
      handler: function () {
        /* when special needs selection changes, uncheck apply config */
        this.apply_preset_customization = false
      },
      deep: true
    },
    acc_pass() {
      if (this.acc_pass == false) {
        this.child_password = []
      }
    }
  },
  methods: {
    handleSelectionChange() {
      if (this.nee_selected.length > 1) {
        this.nee_selected = [this.nee_selected[this.nee_selected.length - 1]];
      }
    },
    showSettings(nee_name) {
      const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

      let b_day = this.dreamer.birthdate;
      let age = getAge(b_day)

      let wv = [1];
      if (nee_name == null) {
        //no NEE
        if (age <= 7) {
          wv = [2]
        } else if (age >= 10) {
          wv = [5]
        }
      } else {
        if (age > 7) {
          wv = [3]
        } else {
          wv = [4]
        }
      }

      switch (nee_name) {
        case "attention_deficit_hyperactivity_disorder":
          let settings_1 = {}
          settings_1['world_visualization'] = wv
          settings_1['reading_mode'] = [2]
          settings_1['no_rush'] = [1]
          //settings_1['accessibility_settings'] = [2]
          return settings_1
        case "autism_spectrum_disorder":
          let settings_2 = {}
          settings_2['world_visualization'] = wv
          settings_2['reading_mode'] = [3]
          settings_2['font_type'] = [2]
          settings_2['no_rush'] = [1]
          //settings_2['accessibility_settings'] = [1,2]
          return settings_2
        case "intellectual_disability":
          let settings_3 = {}
          settings_3['world_visualization'] = wv
          settings_3['reading_mode'] = [3]
          settings_3['font_type'] = [2]
          settings_3['no_rush'] = [1]
          //settings_3['accessibility_settings'] = [1,2,3]
          return settings_3
        case "motor_impairment":
          let settings_4 = {}
          settings_4['world_visualization'] = wv
          settings_4['reading_mode'] = [2]
          settings_4['font_type'] = [2]
          settings_4['no_rush'] = [1]
          //settings_4['accessibility_settings'] = [1,2,3]
          return settings_4
        case "hearing_impairment":
          let settings_5 = {}
          settings_5['reading_mode'] = [0]
          settings_5['font_type'] = [0]
          settings_5['no_rush'] = [1]
          //settings_5['accessibility_settings'] = [5]
          return settings_5
        case "visual_impairment":
          let settings_6 = {}
          settings_6['reading_mode'] = [1]
          settings_6['no_rush'] = [1]
          settings_6['accessibility_settings'] = [1]
          return settings_6
        case "learning_disorders":
          let settings_14 = {}
          settings_14['reading_mode'] = [2]
          settings_14['font_type'] = [0]
          settings_14['no_rush'] = [1]
          //settings_14['accessibility_settings'] = [2]
          return settings_14
        case "language_delays": //dyslexia
          let settings_13 = {}
          settings_13['reading_mode'] = [2]
          settings_13['font_type'] = [4]
          settings_13['no_rush'] = [1]
          //settings_13['accessibility_settings'] = [1,2]
          return settings_13
        default:
          let settings = {}
          settings['world_visualization'] = wv
          settings['reading_mode'] = [2]
          settings['font_type'] = [0]
          settings['no_rush'] = [0]
          return settings
      }
    },
    emptyPassword() {
      this.child_password = [];
    },
    addPassword(index) {
      if (this.child_password.length < 3) {
        this.child_password.push(index);
      }
    },
    makeToast(variant = null, tit = '', msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    hasHistory() {
      return window.history.length > 2
    },
    validate() {
      let valid = true
      if (this.acc_pass) {
        let pass_length = 2
        if (this.dreamer.age > 5) {
          pass_length = 3
        }
        if (this.child_password.length > 0 && this.child_password.length != pass_length) {
          valid = false
          this.error_pass = true
        }
      }
      return valid
    },
    async submitChanges() {
      if (this.validate()) {
        this.error_pass = false
        if (this.child_password.length == 0) {
          this.acc_pass = false
        }
        let data = {
          'id': this.dreamer.id,
          'password': this.child_password,
          'nee': this.nee_selected.map(n => n.id),
          'topics': this.topics_selected.map(t => t.id),

          "has_nee": this.has_nee,
          "has_topics": this.has_topics,
          "has_password": this.acc_pass,

          "apply_preset_customization": this.apply_preset_customization,

          'tab': 'settings',
        }
        await this.$http.post(editSchoolDreamer, data).then(response => {
          if (response.data.status == 200) {
            this.$emit('refetch-data')
            this.makeToast('success', this.$t('Success'), this.$t('message.' + response.data.msg_code));
          } else {
            this.makeToast('danger', this.$t('Error'), this.$t('message.' + response.data.msg_code));
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';


/* CHILDPASSWORD */
.childpassword {
  text-align: center;
}

.childpassword__container--selector {
  width: 200px;
  height: 200px;
}

.childpassword__container {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px;
  background-color: #b5eff9;
  border-radius: 8px;
}

.childpassword__item--button {
  cursor: pointer;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.childpassword__item {
  height: 60px;
  width: 60px;
  background-size: cover;
}

.childpassword__delete {
  width: 200px;
  height: 30px;
  margin: 20px auto 5px auto;
}

.childpassword__container--result {
  width: 200px;
  height: 80px;
  margin: 20px auto 5px auto;
}

.childpassword__item>.order {
  background-color: #68c500;
  color: white;
  display: block;
  border-radius: 50%;
  position: relative;
  top: -64px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
}
</style>
